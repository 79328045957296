<template>
    <router-view  class="full-height-layout" ref="routeview"></router-view>
</template>

<script>

export default {
    name: 'AttributesView',
}
</script>
